@import '../App.scss';
@import './fonts.scss';

@mixin title-font {
  font-family: 'Tecla Bold', Helvetica, sans-serif;
  font-style: bold;
  font-size: 50px;
  line-height: 54px;
  margin: 10px 0 0 0;
  padding: 0;

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 36px;
  }

  @media (max-width: 375px) {
    font-size: 30px;
    line-height: 34px;
  }
}

@mixin subtitle-font {
  font-family: 'Tecla', Helvetica, sans-serif;;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 44px;
  margin: 10px 0 0 0;
  padding: 0;
  @media (max-width: 768px) {
    font-size: 22px;
    line-height: 24px;
    margin: 10px 0 0 0;
  }

  @media (max-width: 375px) {
    font-size: 20px;
    line-height: 24px;
    margin: 10px 0 0 0;
  }
}

@mixin text-font {
  font-family: 'Tecla', Helvetica, sans-serif;;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
  margin: 10px 0 0 0;
  padding: 0;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 24px;
  }
  @media (max-width: 375px) {
    font-size: 16px;
    line-height: 22px;
  }
}

@mixin button-font{
  font-family: 'Tecla Bold', Helvetica, sans-serif;;
  font-style: bold;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (max-width: 375px) {
    font-size: 14px;
    line-height: 18px;
  }
}

@mixin text-bold{
  font-family: 'Tecla Bold', Helvetica, sans-serif;;
  font-style: bold;
}

@mixin text-normal{
  font-family: 'Tecla', Helvetica, sans-serif;;
  font-style: normal;
}

@mixin max-width {
  max-width: 1024px;
  
  @media (max-width: 1024px) {
    max-width: 768px;
  }
  
  @media (max-width: 768px) {
    max-width: 90%;
  }
  
  @media (max-width: 375px) {
    max-width: 350px;
  }
}