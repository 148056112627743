@import "../../index.scss";
@import '../../vendor/mixins.scss';


.partners {
  @include max_width;
  text-align: center;
  margin: 0 auto;
  padding: 60px 0;
  @media (max-width: 768px) {
    padding: 30px 0;
  }

  &__subtitle {
    @include subtitle_font;
  }

  &__title {
    @include title_font;
  }

  &__images {
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: center;
    align-items: center;
    margin-top: 20px ;

    &-image {
      width: 20%;

    }
  }
}