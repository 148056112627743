@import "../../index.scss";
@import '../../vendor/mixins.scss';
@import '../../vendor/constants.scss';

.cta {
  @include text-normal;
  overflow: visible;
  width: 100%;
  position: relative;
  padding: 30px 0;
  margin: 0 auto;
  text-align: center;

  background: $orange;
  color: white;

  &::after {
    position: absolute;
    content: "";
    left:0;
    top: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(0 0, 0 0, 100% 100%, 0% 100%);
    background-color: $blue;
    z-index: 2;
  }
  
  &__container {
    @include max-width;
    margin: auto
  }


  &-title {
    @include title-font;
    position: relative;
    z-index: 5;
  }

  &-subtitle {
    @include subtitle-font;
    position: relative;
    z-index: 5;
  }

  &__form {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 5;
    @media (max-width: 768px) {
      margin-top: 25px;
    }

    &-input {
      width: 80%;
      margin: 10px auto;
      background: #FFFFFF;
      border-radius: 10px;
      padding: 10px;
      border: none;
      font-size: 16px;
      @media (max-width: 768px) {
        font-size: 12px;
        margin: 5px auto;
      }
    }

    &-button {
      border: none;
      text-transform: uppercase;
      background: white;
      border-radius: 10px;
      padding: 10px 30px;
      margin: 20px auto;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.1em;
      color: #404040;
      transition: .5s;
      cursor: pointer;
      &:hover{
        background: rgba(255, 255, 255, 0.75);
      }
      @media (max-width: 768px) {
        padding: 5px 20px;
        margin: 10px auto;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
      }
    }

  }
}