@import "../../index.scss";
@import '../../vendor/mixins.scss';

.about-company{
  @include max-width;
  margin: 100px auto 0 auto;

  &__title{
    @include title-font;
    position: relative;
    margin: 20px auto;
    width: 100%;
    color: $orange;
  }
  
  &__title::after{
    position: absolute;
    content: "";
    z-index: 2;
    width:  220px;
    height: 6px;
    border-bottom: 6px solid $blue;
    top: 20px;
    left: 320px;
  }

  &__subtitle{
    margin: 0;
    @include text-font;
    color: $blue;
  }
}

@media (max-width: 520px) {
  .about-company{
    &__title{
      margin-left: 15px;
      font-size: 30px;
      line-height: 1.2;
    }
    
    &__title::after{
      width:  70px;
      height: 3px;
      border-bottom: 4px solid $blue;
      top: 15px;
      left: 190px;
    }
  }
}