@import "../../index.scss";
@import "../../vendor/mixins.scss";

.news-section{
  margin: 70px auto 0 auto;
  width: 100%;

  &__title{
    @include max-width;
    @include title-font;
    position: relative;
    margin: 20px auto;
    width: 100%;
    color: $orange;
  }
  
  &__title::after{
    position: absolute;
    content: "";
    z-index: 2;
    width:  220px;
    height: 6px;
    border-bottom: 6px solid $blue;
    top: 20px;
    left: 250px;
  }
  
  &__button{
    cursor: pointer;
    display: block; 
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    margin: 20px auto;

    &:disabled{
      opacity: 0;
      cursor: default;
    }
  }
  
  &__button_image{
    width: 60px;
  }
}

@media (max-width: 520px) {
  .news-section{
    &__title{
      margin-left: 15px;
      font-size: 30px;
      line-height: 1.2;
    }
    
    &__title::after{
      width:  70px;
      height: 3px;
      border-bottom: 4px solid $blue;
      top: 15px;
      left: 170px;
    }
  }
}
