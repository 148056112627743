.body{
  padding: 0;
  margin: 0;
}

.body_non-scroll {
  overflow: hidden;
}

html{
  scroll-behavior: smooth;
}

.news{
  position: relative;
}

#root{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

input, textarea{
  box-sizing: border-box;
}