@import "../../index.scss";
@import "../../vendor/mixins.scss";

.popup {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  flex-direction: column;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  display: flex;
  transition: visibility 0.5s, opacity 0.5s;
  z-index: 5;
}

.popup_open {
  visibility: visible;
  opacity: 1;
}

.popup__container {
  border-radius: 30px;
  max-width: 75vw;
  background-color: white;
  width: 50%;
  margin: auto;
  display: block;
  position: relative;
}

.popup__exit-button {
  width: 32px;
  height: 32px;
  position: absolute;
  top: -35px;
  right: -35px;
  border: none;
  box-sizing: border-box;
  border-radius: 2px;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
}

.popup__exit-button-image {
  width: 100%;
  height: 100%;
}

.popup__title {
  @include title-font;
  text-align: center;
  color: black;
  width: 80%;
  font-size: 22px;
  line-height: 25px;
  margin: 20px auto 10px auto;
}

.popup__text {
  @include subtitle-font;
  color: black;
  width: 80%;
  font-size: 20px;
  line-height: 30px;
  margin: 0 auto 20px auto;
}

.popup__subtitle {
  @include subtitle-font;
  position: absolute;
  left: 30px;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  margin: 10px 0 0 0;
  color: #ffffff;
}

.popup__exit-button:hover {
  opacity: 0.6;
}

@media (max-width: 520px) {
  .popup__exit-button {
    width: 20px;
    height: 20px;
    top: -30px;
    right: -5px;
  }
  .popup__title {
    font-size: 20px;
    line-height: 1.2;
  }

  .popup__text {
    font-size: 15px;
    line-height: 1.2px;
  }

  .popup__subtitle {
    font-size: 15px;
    line-height: 1.2;
  }
}
