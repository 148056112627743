@import "../../index.scss";
@import "../../vendor/mixins.scss";

.moderation {
  @include max-width;
  @include text-normal;
  margin: 50px auto 0;

  &-title {
    @include title-font;
    margin: 30px 0;
  }

  &__post {
    display: grid;
    grid-template-columns: 10fr 1fr;
    align-items: center;
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: start;
      width: 100%;
    }

    &-title {
      margin: 10px 0;
    }

    &-description {
      margin: 0;
    }

    &-button {
      border: none;
      text-transform: uppercase;
      background: red;
      border-radius: 10px;
      padding: 10px 30px;
      margin: 0 auto;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.1em;
      color: white;
      transition: .5s;
      cursor: pointer;

      &:hover {
        filter: alpha(0.5);
      }

      @media (max-width: 768px) {
        padding: 5px 20px;
        margin: 10px auto;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
      }
    }

    &-details {
      margin: 20px 0;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;

    &-input {
      margin: 0 auto;
      width: 100%;
      background: #FFFFFF;
      border-radius: 10px;
      padding: 10px;
      border: 1px solid black;
      font-size: 16px;
      @media (max-width: 768px) {
        font-size: 12px;
        margin: 5px auto;
      }
    }

    &-textarea {
      margin: 0 auto;
      min-height: 100px;
      width: 100%;
      background: #FFFFFF;
      border-radius: 10px;
      padding: 10px;
      border: 1px solid black;
      font-size: 16px;
      @media (max-width: 768px) {
        font-size: 12px;
        margin: 5px auto;
      }
    }

    &-button {
      border: none;
      text-transform: uppercase;
      background: $orange;
      border-radius: 10px;
      padding: 10px 30px;
      margin: 0 auto;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.1em;
      color: white;
      transition: .5s;
      cursor: pointer;

      &:hover {
        filter: alpha(0.5);
      }

      @media (max-width: 768px) {
        padding: 5px 20px;
        margin: 10px auto;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}