@import "../../index.scss";
@import "../../vendor/mixins.scss";
@import "../../vendor/constants.scss";

.objects {
  @include max_width;
  text-align: center;
  margin: 0 auto;
  padding: 30px 0;

  &__title {
    @include title_font;
    margin: 20px auto
  }

  &__text {
    @include text_font;
  }

  &__objects {
    margin: 0;

    &-top {
      display: grid;
      grid-template-columns: 9fr 7fr 1fr;
    }

    &-bottom {
      display: grid;
      grid-template-columns: 8fr 1fr 8fr;
    }
  }

  .object {
    &-image {
      width: 100%;
      height: 100%;
      transition: opacity .5s;
    }

    &-color.blue {
      background: $blue;
    }

    &-color.orange {
      background: $orange;
    }

    &__info {
      position: relative;
      cursor: pointer;

      &-text {
        display: none;
        pointer-events: none;
        transition: display .5s;
        @include text_font;
        position: absolute;
        z-index: 10;
        width: 80%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &__info:hover {
      .object-image:hover {
        opacity: 0.4;
      }

      .object__info-text{
        display: block;
        @media (max-width: 768px) {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
    
  }
}
