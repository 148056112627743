@font-face {
    font-family: "Tecla";
    src: url("../fonts/Tecla/Tecla.ttf") format("truetype"),
        url("../fonts/Tecla/Tecla.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Tecla Bold";
    src: url("../fonts/Tecla/Tecla-Bold.ttf") format("truetype"),
        url("../fonts/Tecla/Tecla-Bold.woff") format("woff");
    font-style: bold;
    font-weight: bold;
}