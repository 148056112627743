@import "../../index.scss";
@import "../../vendor/mixins.scss";

.managment {
  @include max-width;
  margin: 150px auto 0 auto;

  &__info-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__title {
    @include title-font;
    font-size: 30px;
    position: relative;
    margin: 20px auto;
    width: 100%;
    color: $orange;
  }
}

.manager {
  width: 45%;
  margin: 0;

  &__img {
    width: 100%;
    height: 500px;
  }

  &__name {
    @include text-bold;
    font-size: 20px;
    position: relative;
    margin: 10px auto;
    width: 100%;
    color: $orange;
  }

  &__position {
    @include text-normal;
    font-size: 20px;
    position: relative;
    margin: 10px auto;
    width: 100%;
    color: $orange;
  }
}

.quote {
  width: 45%;
  display: flex;
  flex-direction: column;

  &__text {
    position: relative;
    @include text-normal;
    text-align: center;
    font-size: 20px;
    margin: 0;
    width: 100%;
    color: $blue;

    &::before {
      content: '"';
      @include text-bold;
      position: absolute;
      left: -30px;
      top: -40px;
      text-align: center;
      font-size: 100px;
      line-height: 1;
    }

    &::after {
      content: '"';
      @include text-bold;
      position: absolute;
      right: -30px;
      bottom: -80px;
      text-align: center;
      font-size: 100px;
      line-height: 1;
    }
  }

  &__avtor {
    position: relative;
    width: 80%;
    align-self: flex-end;
    @include text-normal;
    font-size: 15px;
    position: relative;
    margin: 30px 0 0 0;
    color: $orange;

    &::before{
      position: absolute;
      content: "";
      z-index: 2;
      width:  40px;
      height: 6px;
      border-bottom: 2px solid $orange;
      top: 0;
      left: -50px;
    }
  }


  &__avtor-position {
    align-self: end;
    width: 80%;
    @include text-normal;
    font-size: 15px;
    position: relative;
    margin: 10px 0 0 0;
    color: $orange;
  }
}


@media screen and (max-width: 768px) {
  .manager__img {
    height: 325px;
  }

  .quote {
  
    &__text {
      font-size: 17px;
  
      &::before {
        font-size: 90px;
      }
  
      &::after {
        font-size: 90px;
      }
    }
  
    &__avtor {
      font-size: 14px;
    }
  
  
    &__avtor-position {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 630px) {
  .managment__info-container {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }
  .quote {
    margin: 50px auto 0 auto;
    width: 90%;
  }

  .manager {
    width: 100%;
    margin: 0;
  
    &__name {
      @include text-bold;
      font-size: 20px;
      position: relative;
      margin: 10px auto;
      width: 100%;
      color: $orange;
    }
  
    &__position {
      @include text-normal;
      font-size: 20px;
      position: relative;
      margin: 10px auto;
      width: 100%;
      color: $orange;
    }
  }
}