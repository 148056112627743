@import '../../vendor/mixins.scss';
@import '../../vendor/constants.scss';

.card {
  position: relative;
  width: 30%;
  margin: 20px 0;

  @media (max-width: 768px) {
    width: 48%;
    margin: 5px 0;
  }

  &__image {
    width: 100%;
  }

  &__text-wrapper {
    display: flex;
    box-sizing: border-box;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 50%;
    z-index: 2;
    left: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
  }

  &__text {
    @include text-normal;
    
    font-size: 24px;
    line-height: 100%;
    color: $blue;
    margin: 0;
    text-transform: uppercase;
    padding: 3%;
    word-wrap: break-word;
    display: block;

    margin: auto;

    @media (max-width: 1020px) {
      font-size: 14px;
    }

    @media (max-width: 400px) {
      font-size: 12px;
    }

  }
}