@import "../../index.scss";
@import "../../vendor/mixins.scss";

.slider {
  @include max-width;
  margin: 0 auto 0 auto;

  &__title {
    @include max-width;
    font-family: "Tecla", Helvetica, sans-serif;
    font-style: bold;
    font-weight: 600;
    font-size: 50px;
    line-height: 1.2;
    position: relative;
    margin: 90px auto 20px auto;
    width: 100%;
    color: $orange;
  }

  &__title::after {
    position: absolute;
    content: "";
    z-index: 2;
    width: 220px;
    height: 6px;
    border-bottom: 6px solid $blue;
    top: 20px;
    left: 350px;
  }

  &-card {
    position: relative;
    margin-top: 50px;
    width: calc(100% * 0.781);
    height: calc(100% * 0.488);
    margin: auto;
  }

  &-card__img {
    width: 100%;
    height: 90%;
  }

  &-card__title {
    @include title-font;
    width: 100%;
    padding: 10px;
    margin: auto;
    text-align: center;
    color: white;
  }

  &-card__title-wrapper {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.swiper {
  width: 90%;
}

.swiper-button-next::after {
  font-weight: bold;
  color: $orange;
}

.swiper-button-prev::after {
  font-weight: bold;
  color: $orange;
}

@media (max-width: 520px) {
  .swiper {
    width: 90%;
  }

  .slider {
    &__title {
      margin-left: 15px;
      font-size: 30px;
      line-height: 1.2;
    }

    &__title::after {
      width: 70px;
      height: 3px;
      border-bottom: 4px solid $blue;
      top: 15px;
      left: 220px;
    }

    &-card {
      width: 100%;
    }

    &-card__img {
      width: 100%;
    }

    &-card__title {
      font-size: 27px;
      width: 80%;
      margin: auto;
      text-align: center;
    }
  }
}
