@import '../../vendor/mixins.scss';

.mainPicture {
  @include max_width;
  margin: 0 auto;
  margin-top: 53px;
  position: relative;


  &__logo {
    position: absolute;
    bottom: 10px;
    left: 10px;
    height: 200px;

    @media (max-width: 768px) {
      height: 100px;
    }
    @media (max-width: 375px) {
      height: 80px;
    }
  }

  &__image {
    width: 100%;
  }

  &__title {
    @include text-bold;
    margin: 0;
    font-size: 40pt;
    position: absolute;
    top: 10px;
    right: 10px;
    text-align: right;
    color: #000DD8;

    @media (max-width: 768px) {
      font-size: 20pt;
    }

    @media (max-width: 375px) {
      font-size: 16pt;
    }
  }
}