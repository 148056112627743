@import "../../index.scss";
@import '../../vendor/mixins.scss';

.button {
  @include button_font;
  cursor: pointer;
  color: white;
  background: $orange;
  border: none;
  height: 50px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 0 30px;
  margin: 10px 0;
  transition: 0.25s ease;
  -webkit-transition: 0.25s ease;
  -moz-transition: 0.25s ease;
  -ms-transition: 0.25s ease;
  -o-transition: 0.25s ease;
  z-index: 3;

  @media (max-width: 768px) {
    height: 30px;
    padding: 0 20px;
    margin: 10px 0;
  }

  &:hover {
    background: rgb(255, 157, 46);
  }

  &__wrapper {
    position: relative;
  }

  &__line {
    width: 100%;
    height: 10px;
    background-color: $blue;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    z-index: -1;
    @media (max-width: 768px) {
      height: 5px;
    }
  }
}